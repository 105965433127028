import { type SubmittableExtrinsic } from '@polkadot/api/types';
import { hexToU8a } from '@polkadot/util';
import { encodeAddress } from '@polkadot/util-crypto';
import { z } from 'zod';
import { type ChainflipToken } from '@/shared/assets/tokens';
import { POLKADOT_PREFIX } from '@/shared/constants';
import { chainflipAssetMap, parseIntOrThrow, hexToUtf8, toCamelCase } from '@/shared/utils';
import { addressTypes, chainflipAssets } from '@/shared/utils/chainflip';
import { hexString, number, string } from './rpc/schemas';

export const importPolkadotExtension = async () => import('@polkadot/extension-dapp');

type EventRecord = Parameters<
  NonNullable<Parameters<SubmittableExtrinsic<'promise'>['signAndSend']>[2]>
>[0]['events'][number];

const addressDecodeMap: Record<ChainflipToken['chain']['chainflipId'], (addr: string) => string> = {
  Bitcoin: hexToUtf8,
  Ethereum: (str) => str,
  Polkadot: (str) => encodeAddress(hexToU8a(str), POLKADOT_PREFIX),
  Arbitrum: (str) => str,
};

const caseInsensitiveAddressType = string
  .transform((asset) => toCamelCase(asset))
  .pipe(z.enum(addressTypes));

const parsers = {
  'liquidityProvider.LiquidityDepositAddressReady': z
    .object({
      depositChainExpiryBlock: z.union([number, hexString]).transform(parseIntOrThrow),
      depositAddress: z
        .record(caseInsensitiveAddressType, string)
        .transform((obj) => Object.values(obj)[0]),
      asset: z.enum(chainflipAssets).transform((asset) => chainflipAssetMap[asset]),
      channelId: number,
    })
    .transform(({ asset, depositAddress, ...rest }) => {
      const address = addressDecodeMap[asset.chain.chainflipId](depositAddress);
      return { asset, depositAddress: address, ...rest };
    }),
} as const;

export type LiquidityDepositAddressReady = z.output<
  (typeof parsers)['liquidityProvider.LiquidityDepositAddressReady']
>;

export const getEventArgs = <P extends keyof typeof parsers>(name: P, records: EventRecord[]) => {
  const record = records.find((r) => `${r.event.section}.${r.event.method}` === name);

  if (!record) return null;

  const { data } = record.event;
  const { names } = data;

  if (!names) return null;

  const parser = parsers[name];

  return parser.parse(Object.fromEntries(names.map((n, index) => [n, data[index].toJSON()])));
};
