import { z } from 'zod';

export const number = z.number();
export const string = z.string();
export const hexString = string.refine((str): str is `0x${string}` => /^0x[0-9a-f]*$/i.test(str));
export const numericString = string.regex(/^[0-9]+$/);
export const hexOrNull = z.union([hexString, z.null()]);
export const stringOrNull = z.union([z.string(), z.null()]);
export const numberOrHex = z.union([number, hexString]).transform((n) => BigInt(n));

export const unsignedInteger = z.union([
  z.union([numericString, hexString]).transform((arg) => BigInt(arg)),
  number.transform((arg) => BigInt(arg)),
]);
