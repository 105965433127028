export const deferredPromise = <T>({
  onSettled,
}: {
  onSettled?: () => void;
} = {}) => {
  let resolve: (value: T) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let reject: (reason?: any) => void;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  promise.finally(() => {
    onSettled?.();
  });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { promise, resolve: resolve!, reject: reject! };
};
