import 'mac-scrollbar/dist/mac-scrollbar.css';
import '@/shared/utils/prelude';
import '../styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { GlobalScrollbar } from 'mac-scrollbar';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'sonner';
import FallbackLayout from '@/shared/components/organisms/FallbackLayout';
import { Provider as RainbowKitWrappedProvider } from '@/shared/components/RainbowKit';
import { getGraphqlClient } from '@/shared/graphql/client';
import { PriceOracleProvider, FundingSDKProvider } from '@/shared/hooks';
import usePlausible from '@/shared/hooks/usePlausible';
import { PolkadotProvider } from '@/shared/hooks/usePolkadot';
import { initSentry } from '@/shared/utils';
import { defaultRainbowConfig } from '@/shared/utils/rainbowkit';
import { POLKADOT_JS_APP_NAME } from '../helpers/constants';
import { StateChainAccountProvider } from '../hooks/useStateChainAccount';
import useStore from '../hooks/useStore';

const FallbackComponent = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => (
  <FallbackLayout
    message={error.message}
    onClose={resetErrorBoundary}
    title="An unexpected error has occured on our end. Please try again later."
    homeLabel="Home"
    refreshLabel="Refresh"
  />
);

initSentry();
const graphqlClient = getGraphqlClient('processor');
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  usePlausible();

  const storeAccount = useStore((state) => state.polkadotAccount);
  const savePolkadotAccount = useStore((state) => state.savePolkadotAccount);

  return (
    <>
      <Head>
        <link rel="icon" href="/chainflip-favicon.ico" sizes="any" />
        <title>Chainflip Broker</title>
        <meta
          name="description"
          content="An efficient cross-chain AMM enabling native asset swaps without wrapped tokens or specialized wallets"
        />

        {/* <meta property="og:url" content="https://demo.chainflip.io" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Chainflip Broker" />
        <meta
          property="og:description"
          content="An efficient cross-chain AMM enabling native asset swaps without wrapped tokens or specialized wallets"
        />
        {/* <meta
          property="og:image"
          content="https://demo.chainflip.io/chainflip-og.png"
        /> */}

        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="demo.chainflip.io" />
        <meta property="twitter:url" content="https://demo.chainflip.io" />
        <meta name="twitter:title" content="Chainflip Swap" />
        <meta
          name="twitter:description"
          content="An efficient cross-chain AMM enabling native asset swaps without wrapped tokens or specialized wallets"
        />
        <meta
          name="twitter:image"
          content="https://demo.chainflip.io/chainflip-og.png"
        /> */}
      </Head>
      <Sentry.ErrorBoundary
        // eslint-disable-next-line react/no-unstable-nested-components
        fallback={({ error, resetError }) => (
          <FallbackLayout
            message={error.message}
            onClose={resetError}
            title="An unexpected error has occured on our end. Please try again later."
            homeLabel="Home"
            refreshLabel="Refresh"
          />
        )}
      >
        <QueryClientProvider client={queryClient}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary onReset={reset} FallbackComponent={FallbackComponent}>
                <ApolloProvider client={graphqlClient}>
                  <RainbowKitWrappedProvider config={defaultRainbowConfig}>
                    <PriceOracleProvider>
                      <PolkadotProvider
                        polkadotAppName={POLKADOT_JS_APP_NAME}
                        storeAccount={storeAccount}
                        savePolkadotAccount={savePolkadotAccount}
                      >
                        <StateChainAccountProvider>
                          <FundingSDKProvider>
                            <GlobalScrollbar skin="dark" />
                            <Component {...pageProps} />
                          </FundingSDKProvider>
                        </StateChainAccountProvider>
                      </PolkadotProvider>
                    </PriceOracleProvider>
                  </RainbowKitWrappedProvider>
                  {/* </Sentry.ErrorBoundary> */}
                </ApolloProvider>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </QueryClientProvider>
        <Toaster expand richColors closeButton theme="dark" visibleToasts={10} />
      </Sentry.ErrorBoundary>
    </>
  );
}

export default dynamic(() => Promise.resolve(MyApp), { ssr: false });
