/* eslint-disable no-param-reassign */
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { InjectedAccountWithMeta } from '@/shared/hooks/usePolkadot';

interface Store {
  polkadotAccount: InjectedAccountWithMeta | null;
  savePolkadotAccount: (account: InjectedAccountWithMeta | null) => void;
}

const useStore = create<Store>()(
  devtools(
    persist(
      immer((set) => ({
        polkadotAccount: null,
        savePolkadotAccount: (account) =>
          set(
            (state) => {
              state.polkadotAccount = account;
            },
            false,
            'savePolkadotAccount',
          ),
      })),
      {
        name: 'cf.broker',
        version: 1,
      },
    ),
  ),
);

export default useStore;
