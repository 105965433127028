import { WsProvider, ApiPromise } from '@polkadot/api';
import { z } from 'zod';
import { TokenAmount, ask, bid, limitOrders, rangeOrder, rangeOrders } from '@/shared/utils';
import {
  assetConstants,
  type ChainflipAsset,
  type ChainflipChain,
  chainflipChains,
} from '@/shared/utils/chainflip';
import {
  hexOrNull,
  hexString,
  number,
  numberOrHex,
  stringOrNull,
  unsignedInteger,
} from './schemas';

type RpcAsset = (typeof assetConstants)[ChainflipAsset]['rpcAsset'];
type ChainAndAsset = { asset: RpcAsset; chain: ChainflipChain };

export const getChainAndAsset = (asset: ChainflipAsset): ChainAndAsset => {
  const { chain, rpcAsset } = assetConstants[asset];
  return { asset: rpcAsset, chain };
};

const flipAmount = z.union([number, hexString]).transform((n) => new TokenAmount(n));

const chainAssetMapFactory = <Z extends z.ZodTypeAny>(parser: Z, defaultValue: z.input<Z>) =>
  z.object({
    Bitcoin: z.object({ BTC: parser }),
    Ethereum: z.object({
      ETH: parser,
      USDC: parser,
      FLIP: parser,
      USDT: parser,
    }),
    Polkadot: z.object({ DOT: parser }),
    Arbitrum: z
      .object({ ETH: parser, USDC: parser })
      .default({ ETH: defaultValue, USDC: defaultValue }),
  });

const amount = hexOrNull.transform((x) => BigInt(x ?? 0));
const balances = chainAssetMapFactory(amount, null);

const none = z.object({
  role: z.enum(['none', 'unregistered']).transform(() => 'none' as const), // TODO: remove 'none' later once the protocol changes are synced
  flip_balance: flipAmount,
});
const broker = z.object({
  role: z.literal('broker'),
  flip_balance: flipAmount,
});
export const liquidityProvider = z.object({
  role: z.literal('liquidity_provider').transform(() => 'lp' as const),
  balances,
  refund_addresses: z.record(z.enum(chainflipChains), stringOrNull),
  flip_balance: flipAmount,
  earned_fees: chainAssetMapFactory(numberOrHex, 0),
});
const validator = z.object({
  role: z.literal('validator'),
  flip_balance: flipAmount,
});

export const accountInfo = z.union([none, broker, liquidityProvider, validator]);

export type AccountInfo = z.output<typeof accountInfo>;
export type LiquidityProvider = z.output<typeof liquidityProvider>;
export type Broker = z.output<typeof broker>;

export const humanReadableRole = (role: z.output<typeof accountInfo>['role']) => {
  if (role === 'broker') return 'Broker';
  if (role === 'lp') return 'Liquidity Provider';
  if (role === 'validator') return 'Validator';
  return 'Unregistered';
};

export const orderSchema = z.union([ask, bid, rangeOrder]);

const poolPriceV2 = z.object({
  sell: numberOrHex.nullable(),
  buy: numberOrHex.nullable(),
  range_order: numberOrHex,
});

const chainBaseAssetMapFactory = <Z extends z.ZodTypeAny>(parser: Z, defaultValue: z.input<Z>) =>
  z.object({
    Bitcoin: z.object({ BTC: parser }),
    Ethereum: z.object({
      ETH: parser,
      FLIP: parser,
      USDT: parser,
    }),
    Polkadot: z.object({ DOT: parser }),
    Arbitrum: z
      .object({ ETH: parser, USDC: parser })
      .default({ ETH: defaultValue, USDC: defaultValue }),
  });

export const swapRate = z
  .object({
    // TODO: simplify when we know how Rust `Option` is encoded
    intermediary: unsignedInteger.optional().nullable(),
    output: unsignedInteger,
  })
  .transform((rate) => ({
    intermediateAmount: rate.intermediary?.toString(),
    egressAmount: rate.output.toString(),
  }));

export const environment = z.object({
  pools: z.object({
    fees: chainBaseAssetMapFactory(
      z.object({
        limit_order_fee_hundredth_pips: z.number(),
      }),
      { limit_order_fee_hundredth_pips: 0 },
    ),
  }),
  ingress_egress: z.object({
    minimum_deposit_amounts: chainAssetMapFactory(numberOrHex, 0),
    ingress_fees: chainAssetMapFactory(numberOrHex.nullable(), 0),
    egress_fees: chainAssetMapFactory(numberOrHex.nullable(), 0),
  }),
});

export const responseValidators = {
  account_info: accountInfo,
  pool_price_v2: poolPriceV2,
  pool_orders: z.object({
    limit_orders: limitOrders,
    range_orders: rangeOrders,
  }),
  swap_rate: swapRate,
  environment,
};

type RpcParamsMap = {
  account_info: [idSs58: string];
  pool_price_v2: [baseAssset: ChainAndAsset, quoteAsset: ChainAndAsset];
  pool_orders: [fromAsset: ChainAndAsset, toAsset: ChainAndAsset, accountId: string];
  swap_rate: [
    fromAsset: ChainAndAsset,
    toAsset: ChainAndAsset,
    amount: `0x${string}`,
    atBlock?: string,
  ];
  environment: [];
};

type RpcCall = keyof RpcParamsMap;

export type RpcReturnValue = {
  [K in RpcCall]: z.infer<(typeof responseValidators)[K]>;
};

export const initRpcWsConnection = async () => {
  let api = null;
  try {
    const wsProvider = new WsProvider(process.env.NEXT_PUBLIC_STATECHAIN_RPC_WS_URL as string);
    api = await ApiPromise.create({ provider: wsProvider, noInitWarn: true });
    return api.isReady;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error connecting to rpc', error);
    return api;
  }
};

export default async function makeRpcRequest<M extends RpcCall>(
  method: M,
  ...args: RpcParamsMap[M]
): Promise<RpcReturnValue[M]> {
  try {
    const result = await fetch(process.env.NEXT_PUBLIC_STATECHAIN_RPC_HTTP_URL as string, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: `cf_${method}`,
        params: args,
        id: '1',
      }),
    });
    const data = await result.json();
    const parsed = responseValidators[method].parse(data.result);
    return parsed as RpcReturnValue[M];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error calling rpc method ${method}`, error);
    throw new Error(`Error calling rpc method ${method}: ${error}`);
  }
}
