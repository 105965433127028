import { createStateChainAccountContext } from '@/shared/hooks/useStateChainAccount';
import { TokenAmount } from '@/shared/utils';
import { type ChainflipAsset, chainflipAssets } from '@/shared/utils/chainflip';
import { POLKADOT_JS_APP_NAME } from '../helpers/constants';

export const { StateChainAccountProvider, useStateChainAccount } = createStateChainAccountContext({
  parseAccountInfo: (account) => {
    const allBalances = Object.fromEntries(
      chainflipAssets.map((asset) => [asset, TokenAmount.fromAsset(0, asset)]),
    ) as Record<ChainflipAsset, TokenAmount>;

    if (account?.role === 'broker') {
      // TODO: get collected fees
      // allBalances = Object.fromEntries(
      //   chainflipAssets.map((asset) => [
      //     asset,
      //     TokenAmount.fromAsset(readAssetValue(account.balances, asset), asset),
      //   ]),
      // ) as Record<ChainflipAsset, TokenAmount>;
    }

    // TODO: calculate collected fees
    const totalBalance = 0;

    const flipBalance = account?.flip_balance ?? null;

    return { flipBalance, totalBalance, allBalances };
  },
  polkadotAppName: POLKADOT_JS_APP_NAME,
});
