import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { type ApiPromise } from '@polkadot/api';
import type { SubmittableResultValue } from '@polkadot/api/types';
import { u8aToHex } from '@polkadot/util';
import { decodeAddress } from '@polkadot/util-crypto';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useChainflipAssetPrices } from '@/shared/hooks';
import { accountInfoRpcKey, getAccountPoolOrderQueryKey } from '@/shared/queryKeys';
import { unreachable, TokenAmount, toCamelCase } from '@/shared/utils';
import { type ChainflipAsset, type ChainflipChain, chainConstants } from '@/shared/utils/chainflip';
import { deferredPromise } from '@/shared/utils/promises';
import { MAX_TICK, MIN_TICK } from '@/shared/utils/tickMath';
import { type ChainflipAccountId, usePolkadot } from './usePolkadot';
import {
  type LiquidityDepositAddressReady,
  importPolkadotExtension,
  getEventArgs,
} from '../utils/polkadot';
import makeRpcRequest, { initRpcWsConnection, type AccountInfo } from '../utils/rpc';

type Address = string;

type SendExtrinsicMap = {
  'open-channel': [asset: ChainflipAsset, boostFee?: number];
  'set-lp-role': [];
  'set-refund-address': [chainId: ChainflipChain, address: Address];
  'withdraw-liquidity': [chainId: ChainflipChain, asset: ChainflipAsset, amount: TokenAmount];
  'redeem-flip': [flipperinoAmount: 'Max' | { Exact: string }, ethAddress: string];
  'set-limit-order': [
    {
      baseAsset: ChainflipAsset;
      quoteAsset: ChainflipAsset;
      side: 'Buy' | 'Sell';
      orderId: bigint;
      tick: number;
      sellAmount: TokenAmount;
    },
  ];
  'set-range-order': [
    {
      baseAsset: ChainflipAsset;
      quoteAsset: ChainflipAsset;
      orderId: bigint;
      lowerTick: number;
      upperTick: number;
      maxBaseAmount: TokenAmount;
      maxQuoteAmount: TokenAmount;
    },
  ];
  'set-broker-role': [];
};

export type DepositChannel = LiquidityDepositAddressReady & {
  blockNumber: number;
};

export type ExtrinsicResultMap = {
  'set-lp-role': { hash: string };
  'set-refund-address': { hash: string };
  'open-channel': DepositChannel;
  'withdraw-liquidity': {
    hash: string;
    blockNumber: number;
    extrinsicIndexInBlock?: number;
  };
  'redeem-flip': { hash: string };
  'set-limit-order': { hash: string };
  'set-range-order': { hash: string };
  'set-broker-role': { hash: string };
};

type SendExtrinsicOptions = { onSubmitted: () => void };

type ExtrinsicArgsWithOptions<T extends keyof SendExtrinsicMap> = [
  { args: SendExtrinsicMap[T] } & SendExtrinsicOptions,
];

function isArgsWithOptions<T extends keyof SendExtrinsicMap>(
  args: SendExtrinsicMap[T] | ExtrinsicArgsWithOptions<T>,
): args is ExtrinsicArgsWithOptions<T> {
  return (
    Array.isArray(args) &&
    args.length === 1 &&
    typeof args[0] === 'object' &&
    args !== null &&
    'args' in args[0] &&
    'onSubmitted' in args[0]
  );
}

export type SendExtrinsic = <T extends keyof SendExtrinsicMap>(
  method: T,
  ...args: SendExtrinsicMap[T] | ExtrinsicArgsWithOptions<T>
) => Promise<ExtrinsicResultMap[T]>;

export type EstimateExtrinsicFee = <T extends keyof SendExtrinsicMap>(
  method: T,
) => Promise<TokenAmount>;

const createError = (dispatchErr: SubmittableResultValue['dispatchError']): Error | null => {
  if (!dispatchErr) return null;
  const { name, section, docs } = dispatchErr.registry.findMetaError(dispatchErr.asModule);

  const err = Error(`${section}.${name}:\n${docs.join(' ')}`, {
    cause: dispatchErr,
  });

  if (err.stack) err.stack = err.stack.split('\n').slice(0, 2).join('\n');

  return err;
};

type RequireKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

type CreateContextOpts<T> = {
  parseAccountInfo: (
    account: AccountInfo | null,
    prices: Record<ChainflipAsset, number | undefined>,
  ) => T;
  polkadotAppName: string;
};

export function createStateChainAccountContext<T>({
  parseAccountInfo,
  polkadotAppName,
}: CreateContextOpts<T>) {
  type Context = {
    connect: VoidFunction;
    account: AccountInfo | null;
    accountInfo: T | null;
    accountId: ChainflipAccountId | null;
    sendExtrinsic: SendExtrinsic;
    estimateExtrinsicFee: EstimateExtrinsicFee;
    hasProvider: boolean;
    isLoading: boolean;
    flipBalance: TokenAmount | null;
  };

  type OnboardedContext = RequireKeys<
    Context,
    'account' | 'accountId' | 'accountInfo' | 'flipBalance'
  >;

  const StateChainAccountContext = createContext<Context | null>(null);

  function StateChainAccountProvider({ children }: { children: React.ReactNode }) {
    const { prices: assetPrices } = useChainflipAssetPrices();
    const [apiPromise, setApiPromise] = useState<ApiPromise | null>(null);
    const isLoading = !apiPromise;
    const { selectedAccount: polkadotAccount, w3Enabled } = usePolkadot();

    const init = useCallback(async () => {
      const api = await initRpcWsConnection();
      if (api) {
        setApiPromise(api);
      }
    }, []);

    const { data: account = null, refetch } = useQuery({
      queryKey: accountInfoRpcKey(polkadotAccount?.id),
      queryFn: () => polkadotAccount && makeRpcRequest('account_info', polkadotAccount.id),
      refetchInterval: 5000,
    });

    const queryClient = useQueryClient();

    useEffect(() => {
      // TODO: check localstorage before init
      init();
    }, []);

    const connect = useCallback(() => {
      if (!apiPromise) {
        init();
      }
    }, [apiPromise]);

    const sendExtrinsic = useCallback(
      async (method, ...rest) => {
        if (!apiPromise) throw new Error('api promise not initialized');
        if (!polkadotAccount) throw new Error('wallet account not initialized');
        if (polkadotAccount.readonly) throw new Error('account is readonly');
        const { web3FromSource, web3Enable } = await importPolkadotExtension();
        await web3Enable(polkadotAppName);
        const injector = await web3FromSource(polkadotAccount.meta.source);
        const signer = injector?.signer;
        if (!signer) throw new Error('could not find signer');

        let args: SendExtrinsicMap[typeof method];
        let opts: SendExtrinsicOptions | undefined;

        if (isArgsWithOptions(rest)) {
          [{ args, ...opts }] = rest;
        } else {
          args = rest;
        }

        switch (method) {
          case 'set-lp-role': {
            const { resolve, reject, promise } = deferredPromise({ onSettled: refetch });

            const unsub = await apiPromise.tx.liquidityProvider
              .registerLpAccount()
              .signAndSend(polkadotAccount.address, { signer }, ({ status, dispatchError }) => {
                const err = createError(dispatchError);

                if (err) {
                  reject(err);
                  return;
                }

                if (status.isInBlock) {
                  resolve({
                    hash: status.asInBlock.toString(),
                  });
                }
              })
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-refund-address': {
            const { resolve, reject, promise } = deferredPromise({
              onSettled: refetch,
            });

            // eslint-disable-next-line prefer-const
            let [chain, address] = args as SendExtrinsicMap['set-refund-address'];
            const { addressType } = chainConstants[chain];

            if (addressType === 'Dot') {
              address = u8aToHex(decodeAddress(address));
            }
            const unsub = await apiPromise.tx.liquidityProvider
              .registerLiquidityRefundAddress({
                [addressType]: address,
              })
              .signAndSend(polkadotAccount.address, { signer }, ({ status, dispatchError }) => {
                const err = createError(dispatchError);

                if (err) {
                  reject(err);
                  return;
                }

                if (status.isInBlock) {
                  resolve({
                    hash: status.asInBlock.toString(),
                  });
                }
              })
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'open-channel': {
            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['open-channel']
            >({ onSettled: refetch });
            const asset = toCamelCase((args as SendExtrinsicMap['open-channel'])[0]);
            const argCount =
              apiPromise.tx.liquidityProvider.requestLiquidityDepositAddress.meta.args.length;
            const requestLiquidityDepositAddressArgs = [
              asset,
              (args as SendExtrinsicMap['open-channel'])[1] || 0,
            ].slice(0, argCount);

            const unsub = await apiPromise.tx.liquidityProvider
              .requestLiquidityDepositAddress(...requestLiquidityDepositAddressArgs)
              .signAndSend(
                polkadotAccount.address,
                { signer },
                async ({ status, events, dispatchError }) => {
                  if (status.isReady) {
                    opts?.onSubmitted();
                  }

                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (!status.isInBlock) return;

                  const eventData = getEventArgs(
                    'liquidityProvider.LiquidityDepositAddressReady',
                    events,
                  );

                  if (eventData) {
                    try {
                      const block = await apiPromise.derive.chain.getHeader(status.asInBlock);

                      resolve({
                        blockNumber: block.number.toNumber(),
                        ...eventData,
                      });
                    } catch (e) {
                      reject(
                        Error(
                          `Failed to fetch block number for block hash: ${status.asInBlock.toString()}`,
                        ),
                      );
                    }
                  } else {
                    reject(Error('failed to parse information from event'));
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'withdraw-liquidity': {
            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['withdraw-liquidity']
            >({ onSettled: refetch });

            if (account?.role !== 'lp') {
              reject(new Error('account is not a liquidity provider'));
              return promise;
            }

            const chain = args[0] as SendExtrinsicMap['withdraw-liquidity'][0];
            const { addressType } = chainConstants[chain];
            const asset = toCamelCase(args[1] as SendExtrinsicMap['withdraw-liquidity'][1]);
            const amount = (args[2] as SendExtrinsicMap['withdraw-liquidity'][2]).toString();
            let refundAddress = account.refund_addresses[chain];
            if (addressType === 'Dot') {
              refundAddress = u8aToHex(decodeAddress(refundAddress));
            }

            const unsub = await apiPromise.tx.liquidityProvider
              .withdrawAsset(amount, asset, {
                [addressType]: refundAddress,
              })
              .signAndSend(
                polkadotAccount.address,
                { signer },
                async ({ status, dispatchError, txIndex }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) {
                    const block = await apiPromise.derive.chain.getHeader(status.asInBlock);
                    resolve({
                      hash: status.asInBlock.toString(),
                      blockNumber: block.number.toNumber(),
                      extrinsicIndexInBlock: txIndex,
                    });
                  }
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'redeem-flip': {
            const { resolve, reject, promise } = deferredPromise<ExtrinsicResultMap['redeem-flip']>(
              { onSettled: refetch },
            );
            const [amount, ethAddress] = args as SendExtrinsicMap['redeem-flip'];

            const unsub = await apiPromise.tx.funding
              .redeem(amount, ethAddress, null)
              .signAndSend(
                polkadotAccount.address,
                { signer },
                async ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-limit-order': {
            const [params] = args as SendExtrinsicMap['set-limit-order'];

            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['set-limit-order']
            >({
              onSettled: () => {
                refetch();
                queryClient.invalidateQueries({
                  queryKey: getAccountPoolOrderQueryKey(
                    params.baseAsset,
                    params.quoteAsset,
                    polkadotAccount.id,
                  ),
                });
              },
            });

            const unsub = await apiPromise.tx.liquidityPools
              .setLimitOrder(
                params.baseAsset,
                params.quoteAsset,
                params.side,
                params.orderId.toString(),
                params.tick.toString(),
                params.sellAmount.toString(),
              )
              .signAndSend(
                polkadotAccount.address,
                { signer },
                async ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-range-order': {
            const [params] = args as SendExtrinsicMap['set-range-order'];
            const { resolve, reject, promise } = deferredPromise<
              ExtrinsicResultMap['set-range-order']
            >({
              onSettled: () => {
                refetch();
                queryClient.invalidateQueries({
                  queryKey: getAccountPoolOrderQueryKey(
                    params.baseAsset,
                    params.quoteAsset,
                    polkadotAccount.id,
                  ),
                });
              },
            });

            const unsub = await apiPromise.tx.liquidityPools
              .setRangeOrder(
                params.baseAsset,
                params.quoteAsset,
                params.orderId.toString(),
                [params.lowerTick.toString(), params.upperTick.toString()],
                {
                  AssetAmounts: {
                    maximum: {
                      base: params.maxBaseAmount.toString(),
                      quote: params.maxQuoteAmount.toString(),
                    },
                  },
                },
              )
              .signAndSend(
                polkadotAccount.address,
                { signer },
                async ({ status, dispatchError }) => {
                  const err = createError(dispatchError);

                  if (err) {
                    reject(err);
                    return;
                  }

                  if (status.isInBlock) resolve({ hash: status.asInBlock.toString() });
                },
              )
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          case 'set-broker-role': {
            const { resolve, reject, promise } = deferredPromise({ onSettled: refetch });

            const unsub = await apiPromise.tx.swapping
              .registerAsBroker()
              .signAndSend(polkadotAccount.address, { signer }, ({ status, dispatchError }) => {
                const err = createError(dispatchError);

                if (err) {
                  reject(err);
                  return;
                }

                if (status.isInBlock) {
                  resolve({
                    hash: status.asInBlock.toString(),
                  });
                }
              })
              .catch(reject);

            return promise.finally(() => unsub?.());
          }
          default:
            return unreachable(method, 'unknown extrinsic method');
        }
      },
      [polkadotAccount, apiPromise, account],
    ) as SendExtrinsic;

    const estimateExtrinsicFee = useCallback(
      async (method) => {
        if (!apiPromise) throw new Error('api promise not initialized');
        if (!polkadotAccount) throw new Error('wallet account not initialized');
        if (polkadotAccount.readonly) throw new Error('account is readonly');
        const { web3FromSource, web3Enable } = await importPolkadotExtension();
        await web3Enable(polkadotAppName);
        const injector = await web3FromSource(polkadotAccount.meta.source);
        const signer = injector?.signer;
        if (!signer) throw new Error('could not find signer');

        let paymentInfo;
        switch (method) {
          case 'set-limit-order': {
            paymentInfo = await apiPromise.tx.liquidityPools
              .setLimitOrder('Flip', 'Usdc', 'Buy', BigInt(Date.now()), 0, String(1e18))
              .paymentInfo(polkadotAccount.address, { signer });
            break;
          }
          case 'set-range-order': {
            paymentInfo = await apiPromise.tx.liquidityPools
              .setRangeOrder(
                'Flip',
                'Usdc',
                BigInt(Date.now()),
                [MIN_TICK.toString(), MAX_TICK.toString()],
                {
                  AssetAmounts: {
                    maximum: {
                      base: String(1e18),
                      quote: String(1e6),
                    },
                  },
                },
              )
              .paymentInfo(polkadotAccount.address, { signer });
            break;
          }
          default:
            throw new Error(`fee estimation not implemented for extrinsic method "${method}"`);
        }

        return TokenAmount.fromAsset(paymentInfo.partialFee.toString(), 'Flip');
      },
      [polkadotAccount, apiPromise, account],
    ) as EstimateExtrinsicFee;

    // flatten all chain balances for easy retrieval
    const accountInfo = useMemo(
      () => parseAccountInfo(account, assetPrices),
      [account, assetPrices],
    );

    const memoizedContext = useMemo(
      () => ({
        account,
        sendExtrinsic,
        estimateExtrinsicFee,
        connect,
        isLoading,
        hasProvider: w3Enabled,
        accountId: polkadotAccount?.id ?? null,
        accountInfo,
        flipBalance: account?.flip_balance ?? null,
      }),
      [account, connect, w3Enabled, isLoading, accountInfo, polkadotAccount?.id],
    );

    return (
      <StateChainAccountContext.Provider value={memoizedContext}>
        {children}
      </StateChainAccountContext.Provider>
    );
  }

  function useStateChainAccount(): Context;
  function useStateChainAccount(onboarded: true): OnboardedContext;
  function useStateChainAccount(onboarded?: true): Context | OnboardedContext {
    const ctx = useContext(StateChainAccountContext);
    if (ctx === null) {
      throw new Error('useStateChainAccount must be used within a StateChainAccountContext');
    }

    if (typeof window !== 'undefined' && onboarded) {
      if (!ctx.account) {
        throw new Error('No account selected');
      }

      if (ctx.account.role !== 'lp') {
        throw new Error('Account is not a liquidity provider');
      }
    }

    return ctx;
  }

  return { StateChainAccountProvider, useStateChainAccount };
}
