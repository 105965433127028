import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { encodeAddress } from '@polkadot/util-crypto';
import { useQuery } from '@tanstack/react-query';
import { CHAINFLIP_PREFIX } from '@/shared/constants';
import { ss58ToHex } from '@/shared/utils';
import { importPolkadotExtension } from '../utils/polkadot';

export const polkadotProviderExists = async () => {
  const { web3Enable } = await importPolkadotExtension();
  const enabledApps = await web3Enable('liquidity-provision');
  return enabledApps.length > 0;
};

export type ChainflipAccountId = `cF${string}`;

export interface InjectedAccountWithMeta {
  idHex: `0x${string}`;
  id: ChainflipAccountId;
  address: string;
  readonly?: false;
  meta: {
    genesisHash?: string | null;
    name?: string;
    source: string;
  };
}

type ReadonlyAccount = Omit<InjectedAccountWithMeta, 'meta' | 'readonly'> & {
  readonly: true;
};

type PolkadotContext = {
  accounts: InjectedAccountWithMeta[];
  w3Enabled: boolean;
  selectedAccount: InjectedAccountWithMeta | ReadonlyAccount | null;
  selectAccount: (account: InjectedAccountWithMeta | null) => void;
};

type RequireKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

type AssertedPolkadotContext = RequireKeys<PolkadotContext, 'selectedAccount'>;

export const checkPolkadotExtension =
  (appName: string) => async (): Promise<Pick<PolkadotContext, 'accounts' | 'w3Enabled'>> => {
    const { web3Accounts, web3Enable } = await importPolkadotExtension();
    await web3Enable(appName);
    let accounts: InjectedAccountWithMeta[] = [];
    const w3Enabled = await polkadotProviderExists();

    if (w3Enabled) {
      const polkadotAccounts = await web3Accounts();
      accounts = polkadotAccounts.map((acct) => ({
        ...acct,
        idHex: ss58ToHex(acct.address),
        id: encodeAddress(acct.address, CHAINFLIP_PREFIX) as ChainflipAccountId,
        readonly: false,
      }));
    }

    return { accounts, w3Enabled };
  };
const Context = createContext<PolkadotContext | null>(null);

type ProviderProps = {
  children: React.ReactNode;
  polkadotAppName: string;
  storeAccount: InjectedAccountWithMeta | null;
  savePolkadotAccount: PolkadotContext['selectAccount'];
};

export const PolkadotProvider = ({
  children,
  polkadotAppName,
  storeAccount,
  savePolkadotAccount,
}: ProviderProps): JSX.Element => {
  const [queryEnabled, setQueryEnabled] = useState(true);
  const { data: polkadotContext = { accounts: [], w3Enabled: false } } = useQuery({
    queryKey: ['polkadotContext'],
    queryFn: checkPolkadotExtension(polkadotAppName),
    refetchInterval: 1000,
    enabled: queryEnabled,
  });
  const router = useRouter();
  const [readonlyAccount, setReadonlyAccount] = useState<ReadonlyAccount>();

  useEffect(() => {
    const { accountId } = router.query;
    if (typeof accountId !== 'string') {
      return;
    }

    try {
      setReadonlyAccount({
        idHex: ss58ToHex(accountId),
        id: encodeAddress(accountId, CHAINFLIP_PREFIX) as ChainflipAccountId,
        address: '',
        readonly: true,
      });
    } catch {
      // pass
    }
  }, [router.query.accountId]);

  const selectedAccount = readonlyAccount ?? storeAccount;

  useEffect(() => {
    if (polkadotContext.w3Enabled && polkadotContext.accounts.length > 0) {
      setQueryEnabled(false);
    }
  }, [polkadotContext]);

  const selectAccount = useCallback(
    (account: InjectedAccountWithMeta | null) => {
      if (readonlyAccount) setReadonlyAccount(undefined);
      savePolkadotAccount(account);
    },
    [readonlyAccount, savePolkadotAccount],
  );

  const value = useMemo(
    () => ({
      ...polkadotContext,
      selectedAccount,
      readonly: Boolean(selectedAccount?.readonly),
      selectAccount,
    }),
    [polkadotContext, selectedAccount],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function usePolkadot(): PolkadotContext;
export function usePolkadot(assertAccountSelected: true): AssertedPolkadotContext;
export function usePolkadot(
  assertAccountSelected?: true,
): PolkadotContext | AssertedPolkadotContext {
  const context = useContext(Context);
  if (context === null) {
    throw new Error('usePolkadot must be used within a PolkadotProvider');
  }

  if (assertAccountSelected) {
    if (context.selectedAccount === null) {
      throw new Error('No Polkadot account selected');
    }
  }

  return context;
}
